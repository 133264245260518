'use client';
import { I18nextProvider } from 'react-i18next';
import { HydrationProvider, Client } from 'react-hydration-provider';
import React from 'react';

import { ConfigProvider } from 'antd';
import ko_KR from 'antd/locale/ko_KR';
import en_US from 'antd/locale/en_US';

import i18n from '@shared/locales';

const generateLocale = () => {
  switch (i18n.language) {
    case 'ko-KR':
      return ko_KR;
      break;
    case 'en-US':
      return en_US;
      break;
    default:
      return ko_KR;
      break;
  }
};

const withTheme = (node: JSX.Element) => (
  <>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#82695e',
          fontSizeHeading2: 24,
          fontSizeHeading3: 21,
          margin: 16,
          marginXS: 8,
          marginXXS: 4,
        },
      }}
      locale={generateLocale()}
    >
      <HydrationProvider>
        <I18nextProvider i18n={i18n}>
          <Client>{node}</Client>
        </I18nextProvider>
      </HydrationProvider>
    </ConfigProvider>
  </>
);

export default withTheme;
